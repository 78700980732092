
import { Component, Vue } from 'vue-property-decorator'
import { InsectPestAddInfo } from '@/types/pestControl'
import { FileInfo } from '@/types/common'

@Component({
  name: 'diseaseDetail',
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgList = list.map(item => {
        return item.filePrefix + item.fileUrl
      })
      return imgList || []
    }
  }
})
export default class extends Vue {
  private detailInfo: InsectPestAddInfo = {
    pestsName: '',
    scientificName: '',
    pestsOtherName: '',
    pestsType: '',
    orders: '',
    family: '',
    genus: '',
    happenArea: '',
    harmPlants: '',
    harmPart: '',
    medicineKind: '',
    happenTime: '',
    resourceList: [],
    describes: ''
  }

  get pestsId () {
    return this.$route.params.pestsId || ''
  }

  created () {
    this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.pestControl.selectYhPestsByPestsId, {
      pestsId: this.pestsId
    }).then(res => {
      this.detailInfo = res || {}
    })
  }
}
